<template>
    <div>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <b-alert
                                :show="segundosMensajeActualizacion"
                                dismissible
                                :variant="mensaje.variant"
                                @dismissed="segundosMensajeActualizacion = 0"
                                @dismiss-count-down="
                                    actualizarSegundosMensajeActualizacion
                                "
                            >
                                {{ mensaje.texto }}
                            </b-alert>
                            <div class="d-flex">
                                <div
                                    class="card-title col-auto me-auto titulo-tarjetas"
                                >
                                    Informe Diario
                                </div>
                                <Menu :lugarInstalacion="lugarInstalacion" />
                            </div>
                            <h5 class="card-title-desc mt-30-576 mt-30-1024px">
                                {{ proyectoSeleccionado.nombre_proyecto }} -
                                {{ lugarInstalacion.codigo_proyecto }} -
                                {{ lugarInstalacion.codigo_lugar_instalacion }}
                            </h5>
                            <div>
                                <button
                                    class="btn btn-primary col-auto"
                                    @click="abriModalAgregar"
                                >
                                    <i class="mdi mdi-plus me-1"></i>
                                    Agregar Avance Diario
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="card">
                    <div class="card-body">
                        <label for="fechaInforme" class="fs-6"
                            >Seleccionar Día</label
                        >
                        <VueDatePicker
                            v-model="fechaSeleccionada"
                            @input="obtenerAvanceDiario()"
                            placeholder="Seleccionar Fecha"
                        />
                    </div>
                </div>

                <div class="card" v-if="proyectoAvanceDiarios.observacion != null">
                    <div class="card-body">
                        <label for="fechaInforme" class=""
                            >Observación Día</label
                        >
                        <div>
                            {{ proyectoAvanceDiarios.observacion }}
                        </div>
                    </div>
                </div>
                <div
                    class="tarjeta-actividad"
                    v-for="(actividad, i) in proyectoAvanceDiarios.actividades"
                    :key="i"
                >
                    <div class="d-flex justify-content-end">
                        <b-dropdown
                            class="menu-tarjeta-actividad"
                            variant="primary"
                            menu-class="dropdown-menu-end"
                            right
                            size="sm"
                            text="Opciones"
                        >
                            <template v-slot:button-content>
                                Opciones
                                <i class="mdi mdi-chevron-down"> </i>
                            </template>
                            <b-dropdown-item
                                v-if="rolModuloPermiso.escritura"
                                href="javascript: void(0);"
                                @click="editarAvanceActividad(actividad)"
                                >Editar</b-dropdown-item
                            >
                            <b-dropdown-item
                                v-if="rolModuloPermiso.escritura"
                                href="javascript: void(0);"
                                @click="eliminarAvanceActividad(actividad)"
                                >Eliminar</b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                    <div class="fs-4 actividad mb-3 text-primary">
                        {{ actividad.actividad }}
                    </div>
                    <div class="avance row">
                        <div class="col-10">
                            Avance
                        </div>
                        <div class="col-2 text-center">
                            <span
                                class="badge badge-pill badge-soft-success font-size-18"
                            >
                                {{
                                    Math.round(
                                        (actividad.acumulado_total /
                                            actividad.cantidad_total) *
                                            100
                                    )
                                }}%</span
                            >
                        </div>
                    </div>

                    <div class="datos-actividad">
                        <div class="avanceHoy mt-3 row">
                            <div class="col-10">
                                Avance Hoy
                            </div>
                            <div class="col-2 text-center">
                                <span
                                    class="badge badge-pill badge-soft-primary font-size-13"
                                    >{{ actividad.acumulado_hoy }}</span
                                >
                            </div>
                        </div>
                        <div class="unidad mt-3 row">
                            <div class="col-10">
                                Unidad
                            </div>
                            <div class="col-2 text-center">
                                <span
                                    class="badge badge-pill badge-soft-primary font-size-13"
                                    >{{ actividad.nombre_unidad }}</span
                                >
                            </div>
                        </div>
                        <div class="comentario">
                             <label for="fechaInforme" class="mt-4 fw-bold">Observación</label>
                            <div>
                                {{actividad.observacion}}
                            </div>
                        </div>
                    </div>

                    <label for="fechaInforme" class="mt-4 fw-bold">Funcionarios</label>
                    <div class="funcionarios">
                        <div
                            class="funcionario mt-2"
                            v-for="(personal, indice) in actividad.personal"
                            :key="indice"
                        >
                            <div class="row">
                                <div class="col-10">
                                    {{ personal.nombre_personal_obra }}
                                </div>
                                <div class="col-2 text-center fw-bold">
                                    {{ personal.horas_trabajadas }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>

        <b-modal
            v-model="mostrarModalAgregar"
            :title="
                lugarInstalacion.codigo_proyecto +
                    ' - ' +
                    lugarInstalacion.codigo_lugar_instalacion
            "
            hide-footer
            size="lg"
        >
            <b-overlay :show="loadingForm" variant="white" rounded="sm" no-wrap>
            </b-overlay>

            <AgregarActividadInformeDiario
                @cerrarModal="cerrarModalAgregar"
                :proyectoAvanceDiario="proyectoAvanceDiarios"
                :actividadAvance="actividadAvance"
                :editar="editar"
                @recargarTareas='obtenerAvanceDiario'
            />
        </b-modal>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { tipoProyectoMethods, tipoProyectoComputed } from "@/state/helpers";
import { actividadMethods, actividadComputed } from "@/state/helpers";
import {
    actividadProyectoMethods,
    actividadProyectoComputed,
} from "@/state/helpers";
import {
    actividadProyectoAvanceDiarioMethods,
    actividadProyectoAvanceDiarioComputed,
} from "@/state/helpers";
import {
    actividadProyectoPersonalObraHorasTrabajadasMethods,
    actividadProyectoPersonalObraHorasTrabajadasComputed,
} from "@/state/helpers";
import {
    actividadTipoProyectoConfiguracionMethods,
    actividadTipoProyectoConfiguracionComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import Swal from "sweetalert2";
import Menu from "@/components/widgets/menu/MenuWidget";
import AgregarActividadInformeDiario from "./agregar-actividad-informe-diario.vue";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    components: {
        Menu,
        AgregarActividadInformeDiario,
    },

    data() {
        return {
            mostrarModalAgregar: false,
            loading: false,
            loadingForm: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            lugarInstalacion: {},
            proyectos: [],
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "Proyecto",
            sortDesc: false,

            codigoProyecto: null,
            codigoLugarInstalacion: null,
            guardarCerrar: true,
            actividadProyectoSeleccionada: {},
            actividadesProyecto: [],
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            tipoProyectos: [],
            actividades: [],
            tipoProyectosCargando: false,
            actividadesCargando: false,
            actividadesProyectoCargando: false,
            unidades: [
                { value: "un", label: "un" },
                { value: "m", label: "m" },
                { value: "m3", label: "m3" },
                { value: "kg", label: "kg" },
                { value: "ton", label: "ton" },
                { value: "día", label: "día" },
            ],
            fechaInicioValidar: null,
            fechaTerminoValidar: null,
            form: {
                actividad: null,
                dependeDe: null,
                duracion: null,
                fechaInicio: null,
                fechaTermino: null,
                total: null,
                ponderacion: null,
                unidad: null,
            },
            rolModuloPermiso: {},
            proyectoPersonalObra: [],
            proyectoPersonalObraCargando: false,
            personalObraCargando: false,
            personalSeleccionado: null,
            fechaSeleccionada: null,
            proyectoAvanceDiarios: {},
            proyectoAvanceDiariosCargando: false,
            proyectoSeleccionado: {},
            editar: false,
            actividadAvance: {},
        };
    },
    validations: {
        form: {
            actividad: { required },
            dependeDe: { required },
            duracion: { required },
            fechaInicio: { required },
            fechaTermino: { required },
            total: { required },
            ponderacion: { required },
            unidad: { required },
        },
    },
    mounted() {
        if (this.proyectoUsuario == null) {
            this.obtenerProyectoUsuario();
        } else {
            this.proyectoSeleccionado = this.proyectoUsuario;
        }
        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.lugarInstalacion = {
            codigo_lugar_instalacion: this.codigoLugarInstalacion,
            codigo_proyecto: this.codigoProyecto,
        };
        // this.obtenerPersonalObraProyecto();
        // this.obtenerActividadesProyectoLocal();

        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
        
        this.fechaSeleccionada = moment().format('YYYY-MM-DD')
        this.obtenerAvanceDiario();
    },
    methods: {
        ...actividadProyectoPersonalObraHorasTrabajadasMethods,
        ...actividadProyectoAvanceDiarioMethods,
        ...actividadProyectoMethods,
        ...proyectoMethods,
        ...actividadMethods,
        ...actividadTipoProyectoConfiguracionMethods,
        editarAvanceActividad(actividad) {
            this.actividadAvance = actividad;
            this.editar = true;
            this.mostrarModalAgregar = true;
        },
        eliminarAvanceActividad(actividad) {
            console.log(actividad);
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-primary me-2",
                    cancelButton: "btn btn-secondary",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si, Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarActividadProyectoAvanceDiario(
                            {
                                actividadProyectoAvanceDiarioId:actividad.actividad_proyecto_avance_diario_id,
                                actividadProyectoId:actividad.actividad_proyecto_id
                            }
                        )
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Personal eliminado correctamente!!!";
                                     this.obtenerAvanceDiario();
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                               
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        obtenerActividadProyecto(actividadId) {
            this.obtenerActividadProyectoId(actividadId)
                .then((res) => {
                    this.actividadProyecto = res.body;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        abriModalAgregar() {
            this.editar = false
            this.mostrarModalAgregar = true;
        },
        cerrarModalAgregar() {
            this.mostrarModalAgregar = false;
        },
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {});
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        abrirModalNuevo() {
            this.resetForm();
            this.esEditar = false;
            this.submitted = false;
            this.mostrarModal = true;
            this.obtenerActividadesLocal();
        },
        abrirModalEditar(datos) {
            this.actividadProyectoSeleccionada = datos;
            this.resetForm();
            this.obtenerActividadesLocal();

            this.$v.form.dependeDe.$model = datos.depende_de;
            this.$v.form.duracion.$model = datos.duracion;
            this.$v.form.fechaInicio.$model = datos.fecha_inicio;
            this.$v.form.fechaTermino.$model = datos.fecha_termino;
            this.$v.form.total.$model = datos.total;
            this.$v.form.ponderacion.$model = datos.ponderacion;

            this.$v.form.unidad.$model = {
                value: datos.unidad,
                label: datos.unidad,
            };

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },
        obtenerAvanceDiario() {
            this.loading = true;

            this.obtenerActividadProyectoAvanceDiariosFecha({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
                fecha: this.fechaSeleccionada,
            })
                .then((res) => {
                    this.proyectoAvanceDiarios = res.body;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
    computed: {
        ...proyectoComputed,
        ...authUsuarioComputed,
    },
};
</script>
