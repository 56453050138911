<template>
    <div>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="row">
                <div class="col-12">
                    <b-alert
                        :show="segundosMensajeActualizacion"
                        dismissible
                        :variant="mensaje.variant"
                        @dismissed="segundosMensajeActualizacion = 0"
                        @dismiss-count-down="
                            actualizarSegundosMensajeActualizacion
                        "
                    >
                        {{ mensaje.texto }}
                    </b-alert>
                    <!-- <h5 class="card-title-desc mt-30-576 mt-30-1024px">
                                {{ proyectoSeleccionado.nombre_proyecto }} -
                                {{ lugarInstalacion.codigo_proyecto }} -
                                {{ lugarInstalacion.codigo_lugar_instalacion }}
                            </h5> -->

                    <div class="" v-if="seleccionarFecha">
                        <div class="d-flex justify-content-center">
                            <div class="">
                                <label for="fechaInforme" class="fs-5 mb-3"
                                    >Seleccionar Día</label
                                >
                                <VueDatePicker
                                    v-model="fechaSeleccionada"
                                    @input="buscar()"
                                    inline
                                />
                            </div>
                        </div>
                        <div class="row justify-content-md-center mt-4">
                            <div class="col-6">
                                <label>Observaciones:</label>
                                <input
                                    v-model="observacionAvanceDiario"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                        </div>

                        <div class="row justify-content-md-center mt-4">
                            <div class="col-3">
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    class="ms-1"
                                    @click="crearAvanceFecha(false)"
                                    v-if="rolModuloPermiso.escritura"
                                    >Guardar y Cerrar</b-button
                                >
                            </div>
                            <div class="col-3">
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    class="ms-1"
                                    @click="crearAvanceFecha(true)"
                                    v-if="rolModuloPermiso.escritura"
                                    >Guardar y Continuar</b-button
                                >
                            </div>
                        </div>
                    </div>

                    <div v-if="seleccionarActividad">
                        <label for="fechaInforme" class="fs-5 mb-3"
                            >Seleccionar Actividad</label
                        >
                        <div
                            class="d-flex justify-content-center fs-3 fw-bold mb-3"
                        >
                            Día {{ formatDate(fechaSeleccionada) }}
                        </div>

                        <label for="privado" v-if="openAll == false"
                            >Expandir Todo</label
                        >
                        <label for="privado" v-if="openAll"
                            >Contraer Todo</label
                        >
                        <div class="form-check form-switch form-switch-lg mb-3">
                            <input
                                v-model="openAll"
                                class="form-check-input"
                                type="checkbox"
                                @input="cambiarOpenAll"
                            />
                        </div>
                        <v-treeview
                            v-if="renderTreeView"
                            :items="items"
                            open-on-click
                            activatable
                            item-text=""
                            :open-all="openAll"
                        >
                            <template v-slot:prepend="{ item }">
                                <span @click="seleccionarActividadAvance(item)"
                                    ><span class="font-size-12 me-3"
                                        >{{ item.name }}
                                    </span>
                                    <span
                                        class="badge badge-pill badge-soft-primary font-size-12"
                                    >
                                        {{ item.start_date }}</span
                                    >
                                    al
                                    <span
                                        class="badge badge-pill badge-soft-success font-size-12"
                                        >{{ item.end_date }}</span
                                    ></span
                                >
                            </template>
                        </v-treeview>

                        <div>
                            <b-button
                                type="submit"
                                variant="primary"
                                class="ms-1"
                                @click="volverAFechaSeleccionada"
                                v-if="rolModuloPermiso.escritura"
                                >Volver</b-button
                            >
                        </div>
                    </div>

                    <div v-if="avanceActividad">
                        <label for="fechaInforme" class="fs-5 mb-3"
                            >Ingresar Avance de Actividad</label
                        >
                        <div
                            class="d-flex justify-content-center fs-3 fw-bold mb-3"
                        >
                            Día {{ formatDate(fechaSeleccionada) }}
                        </div>
                        <div class="d-flex justify-content-center">
                            <span class="fs-5 fw-bold me-3">Actividad: </span>
                            <span class="fs-5">
                                {{ this.actividadSeleccionada.name }}
                            </span>
                        </div>
                        <div class="row mt-5">
                            <div class="col-lg-6">
                                <label>Avance de hoy:</label>
                                <input
                                    v-model="avanceHoy"
                                    type="text"
                                    @keypress="validarNumero"
                                    class="form-control"
                                />
                            </div>
                            <div class="col-lg-6">
                                <label>Unidad:</label>
                                <div  class="form-control">
                                   {{unidad.nombre_unidad}}
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-12">
                                <label>Observaciones:</label>
                                <input
                                    v-model="observacionActividad"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                        </div>

                        <div class="row mt-4 fs-5">
                            <div class="mb-3">
                                <span class="fw-bold me-3"
                                    >Acumulado Total:</span
                                >
                                <span>{{
                                    acumuladoActividad.suma_acumulado
                                }}</span>
                            </div>
                            <div>
                                <span class="fw-bold me-3"
                                    >Cantidad Final:</span
                                >
                                <span>{{
                                    actividadProyecto.cantidad_total
                                }}</span>
                            </div>
                        </div>

                        <div class="text-end">
                            <b-button
                                type="submit"
                                variant="primary"
                                class="ms-1"
                                @click="volverASeleccionarActividad"
                                v-if="rolModuloPermiso.escritura"
                                >Volver</b-button
                            >
                            <b-button
                                type="submit"
                                variant="primary"
                                class="ms-1"
                                @click="crearAvance(true)"
                                v-if="rolModuloPermiso.escritura"
                                >Guardar y Agregar Funcionario</b-button
                            >
                        </div>
                    </div>

                    <div v-if="ingresarFuncionarios">
                        <label for="fechaInforme" class="fs-5 mb-3"
                            >Ingresar Avance de Actividad</label
                        >
                        <div
                            class="d-flex justify-content-center fs-3 fw-bold mb-3"
                        >
                            Día {{ formatDate(fechaSeleccionada) }}
                        </div>
                        <div class="d-flex justify-content-center">
                            <span class="fs-5 fw-bold me-3">Actividad: </span>
                            <span class="fs-5">
                                {{ this.actividadSeleccionada.name }}
                            </span>
                        </div>
                        <div class="row mt-5">
                            <div class="col-lg-6">
                                <label>Funcionario:</label>
                                <multiselect
                                    v-model="funcionarioSeleccionado"
                                    :options="personalObraActividad"
                                    placeholder="Seleccionar Funcionario"
                                    value="personal_id"
                                    label="nombre"
                                    :searchable="true"
                                    class="helo"
                                    @input="
                                        obtenerPersonalObraProyectoPersonalSeleccionado
                                    "
                                ></multiselect>
                            </div>
                            <div class="col-lg-6">
                                <label>H/H Trabajadas:</label>
                                <input
                                    v-model="horasTrabajadas"
                                    type="text"
                                    @keypress="validarNumero"
                                    class="form-control"
                                />
                            </div>
                        </div>

                        <div class="text-end mt-3">
                            <b-button
                                type="submit"
                                variant="primary"
                                class="ms-1"
                                @click="volverAAvance"
                                v-if="rolModuloPermiso.escritura"
                                >Volver</b-button
                            >
                            <b-button
                                type="submit"
                                variant="primary"
                                class="ms-1"
                                @click="crearHoraPersonalObra(true)"
                                v-if="rolModuloPermiso.escritura"
                                >Guardar</b-button
                            >
                        </div>
                        <table class="tabla-personal-avance">
                            <tr>
                                <th>Personal</th>
                                <th>H/H</th>
                                <th>Opción</th>
                            </tr>
                            <tr
                                v-for="(personal, i) in proyectoPersonalObra"
                                :key="i"
                            >
                                <td>
                                    {{ personal.nombre_personal_obra }}
                                    {{ personal.apellido_paterno }}
                                </td>
                                <td>{{ personal.horas_trabajadas }}</td>
                                <td>
                                    <i
                                        class="bx bx-trash text-primary pointer icono-botom-tabla"
                                        @click="eliminarPersonalObra(personal)"
                                    ></i>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { tipoProyectoMethods, tipoProyectoComputed } from "@/state/helpers";
import { actividadMethods, actividadComputed } from "@/state/helpers";
import { unidadMethods, unidadComputed } from "@/state/helpers";
import {
    actividadProyectoMethods,
    actividadProyectoComputed,
} from "@/state/helpers";
import {
    actividadProyectoAvanceDiarioMethods,
    actividadProyectoAvanceDiarioComputed,
} from "@/state/helpers";
import {
    actividadProyectoPersonalObraHorasTrabajadasMethods,
    actividadProyectoPersonalObraHorasTrabajadasComputed,
    actividadProyectoPersonalObraMethods,
    actividadProyectoPersonalObraComputed,
    personalMethods,
} from "@/state/helpers";
import {
    actividadTipoProyectoConfiguracionMethods,
    actividadTipoProyectoConfiguracionComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    components: {
        Multiselect,
    },
    props: {
        proyectoAvanceDiario: Object,
        actividadAvance: Object,
        editar: Boolean,
    },
    data() {
        return {
            openAll: false,
            actividadProyectoAvanceDiario: {},
            horasTrabajadas: null,
            funcionarioSeleccionado: {},
            unidad: { unidad_id: "", nombre_unidad: "" },
            unidad_id: null,
            avanceHoy: null,
            observacionActividad: null,
            observacionAvanceDiario: null,
            seleccionarFecha: true,
            seleccionarActividad: false,
            avanceActividad: false,
            ingresarFuncionarios: false,
            actividadSeleccionada: {},
            items: [],
            loading: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            lugarInstalacion: {},

            codigoProyecto: null,
            codigoLugarInstalacion: null,
            guardarCerrar: true,
            actividadProyectoSeleccionada: {},
            actividadesProyecto: [],
            mostrarModal: false,

            actividadesProyectoCargando: false,
            rolModuloPermiso: {},
            proyectoPersonalObra: [],
            proyectoPersonalObraCargando: false,
            personalSeleccionado: null,
            fechaSeleccionada: null,
            proyectoAvanceDiarios: [],
            proyectoAvanceDiariosCargando: false,
            proyectoSeleccionado: {},
            personalObraActividad: [],
            actividadDetalle: {},
            actividadProyecto: {},
            acumuladoActividad: {},
            renderTreeView: true,
        };
    },
    mounted() {
        if (this.proyectoUsuario == null) {
            this.obtenerProyectoUsuario();
        } else {
            this.proyectoSeleccionado = this.proyectoUsuario;
        }

        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.lugarInstalacion = {
            codigo_lugar_instalacion: this.codigoLugarInstalacion,
            codigo_proyecto: this.codigoProyecto,
        };
        // this.obtenerPersonalObraProyecto();
        // this.obtenerActividadesProyectoLocal();

        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
        if (this.editar) {
            this.seleccionarFecha = false;
            this.seleccionarActividad = false;
            this.avanceActividad = true;
            this.ingresarFuncionarios = false;

            this.actividadSeleccionada.id = this.actividadAvance.actividad_proyecto_id;
            this.actividadSeleccionada.name = this.actividadAvance.actividad;

            this.observacionActividad = this.actividadAvance.observacion;
            this.avanceHoy = this.actividadAvance.acumulado_hoy;
            this.unidad = {
                unidad_id: this.actividadAvance.unidad_id,
                nombre_unidad: this.actividadAvance.nombre_unidad,
            };
            this.proyectoAvanceDiarios = this.proyectoAvanceDiario;
            this.fechaSeleccionada = this.proyectoAvanceDiario.fecha_avance_diario;

            this.actividadProyectoAvanceDiario.actividad_proyecto_avance_diario_id = this.proyectoAvanceDiario.actividad_proyecto_avance_diario_id;

            this.seleccionarActividadAvance(this.actividadSeleccionada);
            this.buscar();
        } else {
            this.seleccionarFecha = true;
            this.seleccionarActividad = false;
            this.avanceActividad = false;
            this.ingresarFuncionarios = false;
            this.fechaSeleccionada = null;
            this.avanceHoy = null;
            this.unidad = { unidad_id: "", nombre_unidad: "" };
        }
    },
    methods: {
        ...actividadProyectoPersonalObraHorasTrabajadasMethods,
        ...actividadProyectoPersonalObraMethods,
        ...actividadProyectoAvanceDiarioMethods,
        ...actividadProyectoMethods,
        ...proyectoMethods,
        ...actividadMethods,
        ...actividadTipoProyectoConfiguracionMethods,
        ...unidadMethods,
        ...personalMethods,
        cambiarOpenAll() {
            this.openAll = !this.openAll;
            this.renderTreeView = false;
            this.$nextTick(() => {
                this.renderTreeView = true;
            });
        },
        obtenerActividadProyecto(actividadId) {
            this.obtenerActividadProyectoId(actividadId)
                .then((res) => {
                    this.actividadProyecto = res.body;
                    console.log(this.actividadProyecto)
                    this.unidad_id = this.actividadProyecto.unidad
                    this.unidad = {
                        unidad_id: this.actividadProyecto.unidad,
                        nombre_unidad: this.actividadProyecto.nombre_unidad,
                    };
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        obtenerAcumuladoActividadLocal(actividadId) {
            this.obtenerAcumuladoActividad(actividadId)
                .then((res) => {
                    this.acumuladoActividad = res.body;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        volverAAvance() {
            this.avanceActividad = true;
            this.ingresarFuncionarios = false;
        },
        obtenerPersonalLocal() {
            this.personalCargando = true;
            this.obtenerPersonalActivo()
                .then((res) => {
                    this.personalObraActividad = res.body;

                    this.personalCargando = false;
                })
                .catch((error) => {
                    this.personalCargando = false;
                });
        },
        crearAvanceFecha(irActividad) {
            this.mensaje.variant = "";
            this.mensaje.texto = "";
            this.segundosMensajeActualizacion = 0;
            this.loading = true;
            if (this.fechaSeleccionada == null) {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "Debe seleccionar una fecha";
                this.segundosMensajeActualizacion = this.segundos;
                this.loading = false;

                return;
            }
            let avanceDiario = {
                codigo_proyecto: this.codigoProyecto,
                codigo_lugar_instalacion: this.codigoLugarInstalacion,
                acumulado_hoy: null,
                acumulado_hacumulado_totaloy: null,
                cantidad_total: null,
                observacion:
                    this.observacionAvanceDiario != null
                        ? this.observacionAvanceDiario
                        : null,
                fecha_avance_diario: this.fechaSeleccionada,
            };
            this.crearActividadProyectoAvanceDiario(avanceDiario)
                .then((res) => {
                    if (res.status == 201) {

                        this.actividadProyectoAvanceDiario = res.body;
                        //this.mensaje.variant = "success";
                        //this.mensaje.texto = "Personal creado correctamente!!!";
                        if (irActividad) {
                            this.seleccionarActividad = true;
                            this.seleccionarFecha = false;
                        } else {
                            this.$emit("cerrarModal");
                        }
                        this.$emit("recargarTareas");
                    }

                    if (res.status == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto = "El personal ya existe!!!";
                    }

                    //this.segundosMensajeActualizacion = this.segundos;
                    // this.obtenerPersonalObraProyecto();
                    this.loading = false;
                    //this.personalSeleccionado = null;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });
        },
        crearAvance(irFuncionario) {
            this.mensaje.variant = "";
            this.mensaje.texto = "";
            this.segundosMensajeActualizacion = 0;
            this.loading = true;
            this.obtenerPersonalLocal();

            if (this.avanceHoy == null) {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "Debe ingresar el avance de hoy";
                this.segundosMensajeActualizacion = this.segundos;
                this.loading = false;

                return false;
            }

            if (this.avanceHoy > this.actividadProyecto.cantidad_total) {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                    "El avance de hoy no puede superar a la cantidad final";
                this.segundosMensajeActualizacion = this.segundos;
                this.loading = false;

                return false;
            }

            let avanceActividad = {
                actividad_proyecto_avance_diario_id: this
                    .actividadProyectoAvanceDiario
                    .actividad_proyecto_avance_diario_id,
                actividad_proyecto_id: this.actividadSeleccionada.id,
                codigo_proyecto: this.codigoProyecto,
                codigo_lugar_instalacion: this.codigoLugarInstalacion,
                acumulado_hoy: parseInt(this.avanceHoy),
                unidad_id: this.unidad_id,
                observacion:
                    this.observacionActividad != null
                        ? this.observacionActividad
                        : null,
            };
            if (irFuncionario) {
                this.ingresarFuncionarios = true;
                this.avanceActividad = false;
            }

            this.loading = false;
            this.crearActividadProyectoAvanceDiarioDetalle(avanceActividad)
                .then((res) => {
                    if (res.status == 201) {
                        if (irFuncionario) {
                            this.horasTrabajadas = null;
                            this.funcionarioSeleccionado = {
                                value: null,
                                label: null,
                            };
                            this.ingresarFuncionarios = true;
                            this.seleccionarActividad = false;
                        }
                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerPersonalObraProyecto();
                        this.loading = false;
                        this.personalSeleccionado = null;
                        this.$emit("recargarTareas");
                    }
                    if (res.status == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "El avance de hoy supera el 100% de avance total, revise he intente nuevamente.";
                       
                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                        this.avanceActividad = true
                        this.ingresarFuncionarios = false;
                    }
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                  
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });
              
        },
        validarNumero(event) {
            if (event.keyCode < 45 || event.keyCode > 57) {
                event.returnValue = false;
            }
            if (event.keyCode == 45) {
                event.returnValue = false;
            }
            if (event.keyCode == 46) {
                event.returnValue = false;
            }
        },
        volverAFechaSeleccionada() {
            this.seleccionarActividad = false;
            this.seleccionarFecha = true;
        },
        volverASeleccionarActividad() {
            this.seleccionarActividad = true;
            this.avanceActividad = false;
        },
        seleccionarActividadAvance(item) {
            if (item.children != null) return false;
            let actividadProyectoAvanceDiarioDetalle = {
                actividadProyectoAvanceDiarioId: this
                    .actividadProyectoAvanceDiario
                    .actividad_proyecto_avance_diario_id,
                actividadProyectoId: item.id,
            };

            this.obtenerActividadProyecto(item.id);
            this.obtenerAcumuladoActividadLocal(item.id);
            this.obtenerActividadProyectoAvanceDiarioDetalleActividad(
                actividadProyectoAvanceDiarioDetalle
            )
                .then((res) => {
                    this.actividadDetalle = res.body;
                    this.avanceHoy = this.actividadDetalle.acumulado_hoy;
                    this.observacionActividad = this.actividadDetalle.observacion;
                })
                .catch((error) => {});
            this.actividadSeleccionada = item;
            this.seleccionarActividad = false;
            this.avanceActividad = true;
        },
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {});
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },

        buscar() {
            this.obtenerPersonalObraProyecto();
            this.obtenerAvanceDiario();

            this.obtenerActividadesProyectoFechaLocal();
        },
        obtenerActividadesProyectoFechaLocal() {
            this.actividadesProyectoCargando = true;

            this.obtenerActividadesProyectoFecha({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
                fecha: this.fechaSeleccionada,
            })
                .then((res) => {
                    this.items = [];
                    this.actividadesProyecto = res.body;
                    if (this.actividadesProyecto.length > 0) {
                        this.actividadesProyecto.forEach((item) => {
                            if (item.parent != "0") {
                                const armarTodo = this.items.map((obj) => {
                                    if (obj.children != null) {
                                        obj.children.map((sub) => {
                                            if (sub.children != null) {
                                                sub.children.map((sub2) => {
                                                    if (sub2.children != null) {
                                                        sub2.children.map(
                                                            (sub3) => {
                                                                if (
                                                                    sub3.children !=
                                                                    null
                                                                ) {
                                                                    sub3.children.map(
                                                                        (
                                                                            sub3
                                                                        ) => {
                                                                            {
                                                                                sub4.children.map(
                                                                                    (
                                                                                        sub4
                                                                                    ) => {
                                                                                        if (
                                                                                            sub5.id ==
                                                                                            item.parent
                                                                                        ) {
                                                                                            if (
                                                                                                sub5.children ==
                                                                                                null
                                                                                            ) {
                                                                                                sub5.children = [];
                                                                                            }
                                                                                            sub5.children.push(
                                                                                                {
                                                                                                    id:
                                                                                                        item.actividad_proyecto_id,
                                                                                                    name:
                                                                                                        item.actividad,
                                                                                                    start_date: this.formatDate(
                                                                                                        item.start_date
                                                                                                    ),
                                                                                                    end_date: this.formatDate(
                                                                                                        item.end_date
                                                                                                    ),
                                                                                                }
                                                                                            );
                                                                                        }

                                                                                        return sub5;
                                                                                    }
                                                                                );
                                                                            }
                                                                            if (
                                                                                sub4.id ==
                                                                                item.parent
                                                                            ) {
                                                                                if (
                                                                                    sub4.children ==
                                                                                    null
                                                                                ) {
                                                                                    sub4.children = [];
                                                                                }
                                                                                sub4.children.push(
                                                                                    {
                                                                                        id:
                                                                                            item.actividad_proyecto_id,
                                                                                        name:
                                                                                            item.actividad,
                                                                                        start_date: this.formatDate(
                                                                                            item.start_date
                                                                                        ),
                                                                                        end_date: this.formatDate(
                                                                                            item.end_date
                                                                                        ),
                                                                                    }
                                                                                );
                                                                            }

                                                                            return sub4;
                                                                        }
                                                                    );
                                                                }

                                                                if (
                                                                    sub3.id ==
                                                                    item.parent
                                                                ) {
                                                                    if (
                                                                        sub3.children ==
                                                                        null
                                                                    ) {
                                                                        sub3.children = [];
                                                                    }
                                                                    sub3.children.push(
                                                                        {
                                                                            id:
                                                                                item.actividad_proyecto_id,
                                                                            name:
                                                                                item.actividad,
                                                                            start_date: this.formatDate(
                                                                                item.start_date
                                                                            ),
                                                                            end_date: this.formatDate(
                                                                                item.end_date
                                                                            ),
                                                                        }
                                                                    );
                                                                }

                                                                return sub3;
                                                            }
                                                        );
                                                    }

                                                    if (
                                                        sub2.id == item.parent
                                                    ) {
                                                        if (
                                                            sub2.children ==
                                                            null
                                                        ) {
                                                            sub2.children = [];
                                                        }
                                                        sub2.children.push({
                                                            id:
                                                                item.actividad_proyecto_id,
                                                            name:
                                                                item.actividad,
                                                            start_date: this.formatDate(
                                                                item.start_date
                                                            ),
                                                            end_date: this.formatDate(
                                                                item.end_date
                                                            ),
                                                        });
                                                    }

                                                    return sub2;
                                                });
                                            }

                                            if (sub.id == item.parent) {
                                                if (sub.children == null) {
                                                    sub.children = [];
                                                }
                                                sub.children.push({
                                                    id:
                                                        item.actividad_proyecto_id,
                                                    name: item.actividad,
                                                    start_date: this.formatDate(
                                                        item.start_date
                                                    ),
                                                    end_date: this.formatDate(
                                                        item.end_date
                                                    ),
                                                });
                                            }

                                            return sub;
                                        });
                                    }
                                    if (obj.id == item.parent) {
                                        if (obj.children == null) {
                                            obj.children = [];
                                        }
                                        obj.children.push({
                                            id: item.actividad_proyecto_id,
                                            name: item.actividad,
                                            start_date: this.formatDate(
                                                item.start_date
                                            ),
                                            end_date: this.formatDate(
                                                item.end_date
                                            ),
                                        });
                                    }

                                    return obj;
                                });
                            } else {
                                let actividad = {
                                    id: item.actividad_proyecto_id,
                                    name: item.actividad,
                                    start_date: this.formatDate(
                                        item.start_date
                                    ),
                                    end_date: this.formatDate(item.end_date),
                                };
                                this.items.push(actividad);
                            }
                        });
                    }
                    this.actividadesProyectoCargando = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.actividadesProyectoCargando = false;
                });
        },
        obtenerAvanceDiarioId() {
            this.loading = true;

            this.obtenerActividadProyectoAvanceDiarioId()
                .then((res) => {
                    this.proyectoAvanceDiarios = res.body;
                    if (this.proyectoAvanceDiarios != null) {
                        this.observacionAvanceDiario = this.proyectoAvanceDiarios.observacion;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        obtenerAvanceDiario() {
            this.loading = true;

            this.obtenerActividadProyectoAvanceDiariosFecha({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
                fecha: this.fechaSeleccionada,
            })
                .then((res) => {
                    this.proyectoAvanceDiarios = res.body;
                    if (this.proyectoAvanceDiarios != null) {
                        this.observacionAvanceDiario = this.proyectoAvanceDiarios.observacion;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        obtenerPersonalObraProyectoPersonalSeleccionado(item) {
            if(this.funcionarioSeleccionado != null){
                this.loading = true;
                this.obtenerActividadProyectoPersonalObraHorasTrabajadasPersonal({
                    actividad_proyecto_avance_diario_id: this
                        .actividadProyectoAvanceDiario
                        .actividad_proyecto_avance_diario_id,
                    actividad_proyecto_id: this.actividadSeleccionada.id,
                    rut_personal_obra: this.funcionarioSeleccionado.rut,
                })
                    .then((res) => {
                        this.horasTrabajadas = null;
                        if (res.body != null) {
                            this.horasTrabajadas = res.body.horas_trabajadas;
                        }
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                    });
            }
        },
        obtenerPersonalObraProyecto() {
            this.loading = true;

            this.obtenerActividadProyectoPersonalObraHorasTrabajadasPersonalTodo(
                {
                    actividad_proyecto_avance_diario_id: this.actividadProyectoAvanceDiario.actividad_proyecto_avance_diario_id,
                    actividad_proyecto_id: this.actividadSeleccionada.id,
                    fecha: this.fechaSeleccionada,
                }
            )
                .then((res) => {
                    this.proyectoPersonalObra = res.body;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        obtenerPersonalActividad() {
            this.loading = true;

            this.obtenerActividadProyectoPersonalObraHorasTrabajadasPersonalTodo(
                {
                    actividad_proyecto_avance_diario_id: this
                        .actividadProyectoAvanceDiario
                        .actividad_proyecto_avance_diario_id,
                    actividad_proyecto_id: this.actividadSeleccionada.id,
                    fecha: this.fechaSeleccionada,
                }
            )
                .then((res) => {
                    this.proyectoPersonalObra = res.body;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        crearHoraPersonalObra() {
            this.loading = true;

            if (this.funcionarioSeleccionado == null) {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "Debe seleccionar un personal de la obra";
                this.segundosMensajeActualizacion = this.segundos;
                this.loading = false;

                return false;
            }

            if (this.funcionarioSeleccionado.rut == null) {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "Debe seleccionar un personal de la obra";
                this.segundosMensajeActualizacion = this.segundos;
                this.loading = false;

                return false;
            }
            if (this.horasTrabajadas == null) {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "Debe ingresar las horas trabajadas";
                this.segundosMensajeActualizacion = this.segundos;
                this.loading = false;

                return false;
            }
            let personalObra = {
                actividad_proyecto_avance_diario_id: this
                    .actividadProyectoAvanceDiario
                    .actividad_proyecto_avance_diario_id,
                actividad_proyecto_id: this.actividadSeleccionada.id,
                codigo_proyecto: this.codigoProyecto,
                codigo_lugar_instalacion: this.codigoLugarInstalacion,
                nombre_personal_obra: this.funcionarioSeleccionado.nombre,
                rut_personal_obra: this.funcionarioSeleccionado.rut,
                fecha_horas: this.fechaSeleccionada,
                horas_trabajadas: parseInt(this.horasTrabajadas),
            };
            this.crearActividadProyectoPersonalObraHorasTrabajadas(personalObra)
                .then((res) => {
                    if (res.status == 201) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto = "Personal creado correctamente!!!";
                        this.$emit("recargarTareas");
                    }

                    if (res.status == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto = "No puede superar las 24 horas";
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto = "Primero debe agregar al personal desde el modulo Gantt";
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    this.obtenerPersonalObraProyecto();
                    this.loading = false;
                    this.personalSeleccionado = null;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });
        },
        eliminarPersonalObra(personal) {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-primary me-2",
                    cancelButton: "btn btn-secondary",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si, Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarActividadProyectoPersonalObraHorasTrabajadas(
                            personal.actividad_proyecto_personal_obra_horas_trabajadas_id
                        )
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Personal eliminado correctamente!!!";
                                    this.$emit("recargarTareas");
                                    if (this.guardarCerrar) {
                                        this.mostrarModal = false;
                                    }
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                                this.obtenerPersonalObraProyecto();
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        irLugarinstalacion(proyecto) {
            this.seleccionarProyecto(proyecto[0]);
            this.$router.push({
                name: "lugaresInstalacion",
                params: { codigoProyecto: proyecto[0].codigo_proyecto },
            });
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
    },
    computed: {
        ...proyectoComputed,
        ...authUsuarioComputed,
    },
};
</script>

<style>
.crearActividadesProyecto {
    margin-right: 10px;
}

.tabla-personal-avance {
    margin-top: 20px;
    width: 100%;
}
.tabla-personal-avance th {
    padding: 10px;
}

.tabla-personal-avance td {
    padding: 10px;
}
</style>
